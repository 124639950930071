import React, { useEffect, useRef, useState } from "react";
import Header from "./components/Header";
import Send from "./components/Send";
import "./index.css";
import { Col, Row, Spin } from "antd";
import { modelling_api } from "../../services/modelling_api";
import { CHATBOT_MODELLING_API_URL } from "../../utils/URLS";
import MessageComponent from "./components/MessageComponent";
import useCustomization from "../../context/CustomizationContext";
import { getCookie, setCookie } from "../../utils/storage";
import { events, getVisitor } from "../../utils/visitor";
import typing_bubble from "../../assets/images/typing_bubble.gif";
import useProjectParams from "../../context/ProjectParamsContext";

const Chat = () => {
  const { getCustomization, getBot } = useCustomization();
  const { convParams, setProjectParams, setSpecificParam } = useProjectParams();

  const [scrollHeight, setScrollHeight] = useState(
    "calc(100vh - 68px - 68px) "
  );
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputFocus, setInputFocued] = useState(false);
  const [visitorMsgs, setVisitorMsgs] = useState([]);
  const [messages, setMessages] = useState([]);
  const [end, setEnd] = useState(false);
  const [botMsgs, setBotMsgs] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [answer, setAnswer] = useState("");
  const [session, setSession] = useState();
  const [visitor, setVisitor] = useState();
  // const [convParams, setConvParams] = useState({
  //   user_id: undefined,
  //   project_id: undefined,
  //   bot_id: undefined,
  //   identifier: undefined,
  // });
  const messageEnd = useRef();

  const handleWindowClick = () => {
    setFocus(false);
  };

  let session_id = localStorage.getItem("session_id");
  let user_id = convParams?.user_id;
  let project_id = convParams?.project_id;
  let bot_id = convParams?.bot_id;

  const scrollToBottom = () => {
    messageEnd.current?.scrollIntoView({ behavior: "smooth" });
  };

  const appendString = (string, msgId) => {
    setBotMsgs((prevBotMsgs) => [...prevBotMsgs, string]);
  };

  const handleMessageFromParent = (event) => {
    try {
      if (typeof event.data === "string") {
        const eventData = JSON.parse(event.data);
        if (eventData.event === "params") {
          if (
            convParams?.user_id &&
            convParams?.project_id &&
            convParams?.bot_id &&
            convParams?.identifier
          ) {
          } else {
            // setCookie("visitor_id", eventData?.identifier);
            localStorage.setItem("visitor_id", eventData?.identifier);

            setProjectParams(eventData);
          }
        } else if (eventData.event === "visitor-details") {
          setVisitor(eventData?.visitorObj);
        }
      }
    } catch (error) {
      console.error("Error parsing message data:", error);
    }
  };

  useEffect(() => {
    // Add event listener to listen for messages from parent window
    window.addEventListener("message", handleMessageFromParent);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("message", handleMessageFromParent);
    };
  }, []);
  const postMessage = async (msgObj) => {
    try {
      setEnd(true);
      setAnswer("");

      let url = `${CHATBOT_MODELLING_API_URL}query?user_id=${msgObj?.user_id}&project_id=${msgObj?.project_id}&question=${msgObj?.question}&bot_id=${msgObj?.bot_id}&session_id=${msgObj?.session_id}`;

      var response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let loopRunner = true;
      let fullAnswer = "";

      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          loopRunner = false;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        setAnswer((answer) => answer + decodedChunk);
        fullAnswer += decodedChunk;
      }

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 1,
          text: fullAnswer,
          timestamp: new Date(),
        },
      ]);
      setEnd(false);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const createConv = async (obj) => {
    try {
      let updatedObj = { ...visitor, ...obj };
      let response = await modelling_api.post(
        `new_conversation?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&visitor_id=${obj?.identifier}`,
        updatedObj
      );

      localStorage.setItem("session_id", response?.data?.session_id);
      setCookie("session_id", response?.data?.session_id);
      setSpecificParam("session_id", response?.data?.session_id);
      let updatedMsg = {
        ...obj,
        session_id: response?.data?.session_id,
      };

      setSessionId(response?.data?.session_id);
      postMessage(updatedMsg);
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendMessage = async (message) => {
    let msg = message?.msg;
    let allMsgsArray = [...visitorMsgs];
    allMsgsArray.push(msg);
    const newObject = {
      id: messages.length + 1,
      text: msg,
      timestamp: new Date(),
      isVisitor: true,
    };
    setVisitorMsgs([...visitorMsgs, msg]);
    setMessages([...messages, newObject]);
    scrollToBottom();

    let session_id = localStorage.getItem("session_id") || sessionId;
    let obj = {
      bot_id: bot_id,
      session_id: session_id,
      user_id: user_id,
      project_id: project_id,
      question: msg,
      identifier: events?.getIdentifier() || localStorage.getItem("visitor_id"),
    };
    if (session_id) {
      postMessage(obj);
    } else {
      createConv(obj);
    }
  };

  const getSingleConversation = async (sessionId) => {
    setLoading(true);

    try {
      let response = await modelling_api.post(
        `get_conversation?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}&session_id=${sessionId}`
      );
      if (response?.data) {
        let questions =
          response?.data?.questions?.map((msgs) => {
            return { ...msgs, isVisitor: true };
          }) ?? [];

        let answers =
          response?.data?.answers?.map((msgs) => {
            return { ...msgs };
          }) ?? [];

        const mergedMessages = questions?.concat(answers);
        mergedMessages?.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
        const arrayOfStrings = questions?.map((obj) => obj.text);
        setVisitorMsgs(arrayOfStrings);

        if (messages?.length === 1) {
          setMessages(messages);
        } else {
          setMessages(mergedMessages);
        }
      }
    } catch (error) {
      if (error?.response?.data?.detail === "No conversation found") {
        console.log("No Conversation found");
        // const initial_msg = {
        // text: customization?.initial_message,
        // timestamp: new Date(),
        // };
        // setMessages([initial_msg, ...messages]);
      }
    } finally {
      setLoading(false);
    }
  };

  const getConversations = async (session_id) => {
    setLoading(true);
    try {
      let response = await modelling_api.post(
        `list_conversations?user_id=${convParams.user_id}&project_id=${convParams.project_id}&bot_id=${convParams.bot_id}&visitor_id=${convParams.identifier}`
      );
      const visitorCoversations = response?.data?.filter(
        (item) => item?.visitor_id === convParams?.identifier
      );

      const currentSession = visitorCoversations[0]?.sessions?.filter(
        (session) => session?.session_id === session_id
      );

      setSession(currentSession[0]);
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }
  };

  const getBotSettings = async (botId, projectId) => {
    setLoading(true);
    await getCustomization(botId, projectId);
    await getBot(botId);
    setLoading(false);
  };

  useEffect(() => {
    if (
      session_id &&
      convParams?.bot_id &&
      convParams?.user_id &&
      convParams?.project_id
    ) {
      getSingleConversation(session_id);
    } else {
      setMessages([]);
    }
  }, [convParams, session_id]);

  useEffect(() => {
    if (convParams?.user_id && session_id) {
      getConversations(session_id);
    }
  }, [convParams?.user_id, session_id]);

  useEffect(() => {
    if (bot_id && project_id) {
      getBotSettings(bot_id, project_id);
    }
  }, [bot_id, project_id]);

  return (
    <Spin spinning={loading}>
      <div onClick={handleWindowClick}>
        <Header />
        <div className="main-sec">
          <div className="scroll" style={{ height: scrollHeight }}>
            <Row className="chat_area_row">
              <Col span={24}>
                <MessageComponent
                  answer={answer}
                  messages={messages}
                  botMsgs={botMsgs}
                  setMessages={setMessages}
                  end={end}
                  setBotMsgs={setBotMsgs}
                  scrollToBottom={scrollToBottom}
                />
              </Col>
            </Row>
            <div ref={messageEnd} />
          </div>
        </div>
        {!session?.sessionEnded && (
          <>
            <div ref={messageEnd} />
            <Send
              scrollToBottom={scrollToBottom}
              setScrollHeight={setScrollHeight}
              setFocus={focus}
              setInputFocued={setInputFocued}
              sendMessage={sendMessage}
              setEnd={setEnd}
              appendString={appendString}
              setMessages={setMessages}
              messages={messages}
              end={end}
              sessionEnded={session?.sessionEnded}
            />
          </>
        )}
      </div>
    </Spin>
  );
};
export default React.memo(Chat);
