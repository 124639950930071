import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { rootRoute } from "./routes";
import { IFrameHelper } from "../utils/Helper";
import useProjectParams from "../context/ProjectParamsContext";

const App = () => {
  const { setSpecificParam } = useProjectParams();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdParam = urlParams.get("project_id");
    const botIdParam = urlParams.get("bot_id");
    const userIdParam = urlParams.get("user_id");

    if (projectIdParam) {
      setSpecificParam("project_id", projectIdParam);
    }
    if (botIdParam) {
      setSpecificParam("bot_id", botIdParam);
    }
    if (userIdParam) {
      setSpecificParam("user_id", userIdParam);
    }
  }, []);

  const registerListeners = () => {
    window.addEventListener("message", (e) => {
      if (typeof e.data === "string" && !e.data.includes("webpackHot")) {
        let message = JSON.parse(e.data);
        if (message.event === "set-visitor") {
          recordVisitor(message);
        }
      }
    });
  };

  const recordVisitor = async (message) => {
    // setCookie("visitor_id", message?.identifier);
    localStorage.setItem("visitor_id", message?.identifier);
    setSpecificParam("visitor_id", message?.identifier);
  };

  useEffect(() => {
    setTimeout(() => {
      sendChatLoaded();
      registerListeners();
    }, 500);
  }, []);

  const sendChatLoaded = () => {
    IFrameHelper.sendMessage({
      event: "chatLoaded",
    });
  };

  return (
    <>
      <Routes>
        {rootRoute.map((route) => (
          <Route key={route.key} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  );
};

export default App;
