import React from "react";
import "./index.css";
import { getDate, getFullDate } from "../../../../utils/Helper";
import { message } from "antd";
import useCustomization from "../../../../context/CustomizationContext";

const ReceiverRowComponent = (message) => {
  let msg = message?.msg;
  let { customization } = useCustomization();
  return (
    <div className="receiver_msg">
      <div
        className="receiver_msg_row"
        style={{
          backgroundColor: customization?.ai_msg_background_color ?? "#f3f4f6",
          color: customization?.ai_font_color ?? "black",
        }}
      >
        <p className="msg_div">{msg?.text}</p>

        <div className="timestamp_div">
          <p
            style={{ color: customization?.ai_font_color ?? "black" }}
            title={getFullDate(msg?.timestamp)}
          >
            {getDate(msg?.timestamp)}
          </p>
        </div>
      </div>
      <br />
    </div>
  );
};

export default ReceiverRowComponent;
